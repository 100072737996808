import React from 'react';
import StackhawkLogo from 'images/stackhawk-white-logo.svg';

export const defaultImage = StackhawkLogo;
export const GetInTouchBannerTestId = 'GetInTouchBanner';
export const defaultHeadline = 'Get In Touch!';
export const defaultSubHeadline = `We’d love to hear from you`;
export const defaultDescription = (
  <>
    Drop us a line at
    <a href="mailto:hello@stackhawk.com" target="_blank" rel="noopener noreferrer" className="ml-1 text-white">
      hello@stackhawk.com
    </a>
  </>
);
