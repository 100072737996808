import React from 'react';
import {
  defaultGeneralInformation,
  defaultHeadline,
  GeneralInformationBlockTestId,
  IInformationBlock,
} from './constants';

export interface Props {
  headline?: React.ReactNode;
  generalInformation?: IInformationBlock[];
}

export const GeneralInformationBlock: React.FC<Props> = ({
  headline = defaultHeadline,
  generalInformation = defaultGeneralInformation,
}) => {
  return (
    <section data-testid={GeneralInformationBlockTestId}>
      <div className="container py-5">
        <h3 className="text-uppercase text-primary text-center fz-18 mb-5">{headline}</h3>
        <div className="row">
          {generalInformation.map((item, idx) => (
            <div className={'col-12 mb-5 mb-md-0 col-md-3'} key={idx}>
              <div className="d-flex">
                {item.url ? (
                  <a
                    target={'_blank'}
                    rel="noopener noreferrer"
                    href={item.url.href}
                    className="mr-3 opacity-80  text-white"
                  >
                    {item.icon}
                    <span className="sr-only">{item.description}</span>
                  </a>
                ) : (
                  <div className="mr-3 opacity-80">{item.icon}</div>
                )}
                <div>
                  {item.url ? (
                    <a
                      target={'_blank'}
                      rel="noopener noreferrer"
                      href={item.url.href}
                      className="text-white fw-800 fz-12 mb-1"
                    >
                      {item.title}
                    </a>
                  ) : (
                    <p className="text-white fw-800 fz-12 mb-1">{item.title}</p>
                  )}
                  <p className="text-white fz-12">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GeneralInformationBlock;
