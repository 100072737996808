import * as React from 'react';
import { graphql } from 'gatsby';
import Default from '../../layouts/default';
import { aboutPageBlockMapper } from '../../core/pageHelpers/about';
import MeetTheTeam, { ITeamMember } from '../../components/MeetTheTeam';
import InvestorsBlock from '../../components/InvestorsBlock';
import GeneralInformationBlock from '../../components/GeneralInformationBlock';
import GetInTouchBanner from '../../components/GetInTouchBanner';
import BannerBlockTextColumns from 'components/BannerBlockTextColumns';
import SEO from 'components/seo';

const About: React.FC<{ data: any }> = ({ data }) => {
  const aboutData: GatsbyTypes.ContentfulPage = data.about;

  const aboutPage = aboutData.edges[0].node;
  const team = aboutPage?.pageBlocks.filter((block) => {
    if (block?.type === 'ContentfulBlogAuthor') {
      return block;
    }
  });

  const seo = aboutPage.seo;

  const teamMembers: ITeamMember[] = team.map((person) => ({
    name: person?.authorName || '',
    position: person?.position || '',
    picture: person?.authorPicture?.gatsbyImageData || null,
  }));
  const blocks = Object.values(aboutPageBlockMapper(aboutPage.pageBlocks));
  return (
    <>
      <Default>
        <SEO data={seo} />
        {blocks.map((block) => block)}
        <BannerBlockTextColumns />
        <MeetTheTeam key={'team'} teamMembers={teamMembers} />
        <InvestorsBlock />
        <GeneralInformationBlock />
        <GetInTouchBanner />
      </Default>
    </>
  );
};

export const query = graphql`
  {
    about: allContentfulPage(filter: { slug: { eq: "about" } }) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...defaultFields
        }
      }
    }
  }
`;

export default About;
