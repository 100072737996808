import React from 'react';
import PageHeader from '../../components/PageHeader';
import SEO from 'components/seo';
import AboutPreHeader from '../../components/AboutPreHeader';
import { genericPageBlockMapper } from './genericPageBlockMapper';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const aboutPageBlockMapper = (blocks: GatsbyTypes.ContentfulPage['pageBlocks']) => {
  const components = genericPageBlockMapper(blocks);
  blocks?.forEach((block, index: number) => {
    switch (block?.internal?.type) {
      case 'ContentfulComponentPageHeader':
        block = block as GatsbyTypes.ContentfulComponentPageHeader;
        components[index] = (
          <PageHeader
            key={index}
            textTop={block?.textTop || ''}
            textMiddle={block?.textMiddle}
            textBottom={block?.textBottom}
            backgroundImage={block?.backgroundImage?.gatsbyImageData}
            subHeaderCls={'bg-dark'}
            subHeader={<AboutPreHeader />}
          />
        );
        break;

      case 'ContentfulSeo':
        block = block as GatsbyTypes.ContentfulSeo;

        components[index] = <SEO key={index} title={block.title || ''} description={block.description} />;
        break;

      default:
        break;
    }
  });

  return components;
};
