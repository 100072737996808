import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToBottom } from '@fortawesome/pro-duotone-svg-icons/faArrowToBottom';
import { faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons/faMapMarkerAlt';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { GITHUB_URL, TWITTER_URL } from '../../core/links';

export const GeneralInformationBlockTestId = 'GeneralInformationBlock';

export interface IInformationBlock {
  title: string;
  description: React.ReactNode;
  icon: React.ReactNode;
  url?: {
    href: string;
    external?: boolean;
  };
}

export const defaultHeadline = `GENERAL INFORMATION / RESOURCES`;
export const defaultGeneralInformation: IInformationBlock[] = [
  {
    title: 'Press Kit',
    description: 'Press Kit Download our press kit, including our Brandbook, Logos, etc.',
    icon: <FontAwesomeIcon className="fz-24" icon={faArrowToBottom} />,
    url: {
      href: '/stackhawk-press-kit.zip',
    },
  },
  {
    title: 'Mailing Address',
    description: (
      <>
        1580 N. Logan St Ste 660
        <br /> PMB 36969
        <br /> Denver, CO 80203
      </>
    ),
    icon: <FontAwesomeIcon className="fz-24" icon={faMapMarkerAlt} />,
  },
  {
    title: 'GitHub',
    description: 'Check out all our open source repos and code on GitHub',
    icon: <FontAwesomeIcon className="fz-24" icon={faGithub} />,
    url: {
      href: GITHUB_URL,
      external: true,
    },
  },
  {
    title: 'X',
    description: 'Follow us on X to keep up to date on all the latest StackHawk news.',
    icon: <FontAwesomeIcon className="fz-24" icon={faXTwitter} />,
    url: {
      href: TWITTER_URL,
      external: true,
    },
  },
];
