import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { GatsbyImage } from 'gatsby-plugin-image';
import { defaultHeadline, meetTheTeamTestId, defaultSubHeadline } from './constants';
import YouImage from '../../images/kaakaww-hex-bw.png';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowAltRight';
import { JOBS_URL } from '../../core/links';

export interface ITeamMember {
  name: string;
  position: string;
  picture: IGatsbyImageData | null;
}

export interface Props {
  teamMembers?: ITeamMember[];
  headline?: React.ReactNode;
  subHeadline?: React.ReactNode;
}

export const MeetTheTeam: React.FC<Props> = ({
  headline = defaultHeadline,
  subHeadline = defaultSubHeadline,
  teamMembers = [],
}) => {
  return (
    <section className="bg-dark container-fluid" data-testid={meetTheTeamTestId} id="team">
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <h3 className="text-primary fz-18 font-weight-bold text-center mb-3 text-uppercase">{headline}</h3>
          </div>
          <div className="col-md-8 col-12 mx-auto mb-7">
            <p className="fz-18 text-center">{subHeadline}</p>
          </div>
        </div>
        {teamMembers.length && (
          <div className="row">
            {teamMembers.map((member, idx) => (
              <div key={idx} className="col-6 col-md-3 text-center mb-5 ">
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {member && member.picture ? (
                  <GatsbyImage className="d-inline-block" alt={member.name || ''} image={member.picture} />
                ) : null}
                <p className="mb-0 mt-2 font-weight-bold">{member.name}</p>
                <p className="mb-0 mt-1 fz-14 text-gray-300">{member.position}</p>
              </div>
            ))}
            <div className="col-6 col-md-3 text-center mb-5">
              <div className="mb-3">
                <img src={YouImage} alt="We're always hiring" className="img-fluid" />
              </div>
              <p className="mb-0 mt-2 font-weight-bold">We’re always hiring!</p>
              <Link to={JOBS_URL} className={'mb-0 mt-1 fz-14 text-primary'}>
                View our open positions <FontAwesomeIcon icon={faLongArrowAltRight} />
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default MeetTheTeam;
