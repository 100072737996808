import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { investorsBlockTestId } from './constants';

export const InvestorsBlock: React.FC = () => {
  return (
    <section className="container-fluid bg-asphalt" data-testid={investorsBlockTestId}>
      <article className="container py-5">
        <h3 className="text-primary text-center fz-18 text-uppercase mb3">Our Investors</h3>
        <p className="mb-5 text-center">Backed by some of the best venture and angel investors in the business.</p>
        <div className="row">
          <div className="col-md-4 col-12 mb-5 text-center d-flex justify-content-center mx-auto align-items-center">
            <StaticImage
              placeholder={'tracedSVG'}
              src="../../images/investors/foundry-logo-white.png"
              alt="Foundry Group"
              key={'foundry'}
            />
          </div>
          <div className="col-md-4 col-12 mb-5 text-center d-flex justify-content-center mx-auto align-items-center">
            <StaticImage
              placeholder={'tracedSVG'}
              src="../../images/investors/costanoa-ventures-logo.png"
              alt="Costanoa Ventures"
              key={'costanoa'}
            />
          </div>
          <div className="col-md-4 col-12 mb-5 text-center d-flex justify-content-center mx-auto align-items-center">
            <StaticImage
              placeholder={'tracedSVG'}
              src="../../images/investors/sapphire-ventures-logo.png"
              alt="Sapphire Ventures"
              key={'sapphire'}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12 mb-5 text-center d-flex justify-content-center ml-auto align-items-center">
            <StaticImage
              placeholder={'tracedSVG'}
              src="../../images/investors/flybridge-logo.png"
              alt="Flybridge Capital Partners"
              key={'flybridge'}
            />
          </div>
          <div className="col-md-4 col-12 mb-5 text-center d-flex justify-content-center mr-auto align-items-center">
            <StaticImage
              placeholder={'tracedSVG'}
              src="../../images/investors/matchstick-logo.png"
              alt="Matchstick Ventures"
              key={'matchstick'}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12 mb-5 text-center d-flex justify-content-center ml-auto align-items-center">
            <StaticImage
              placeholder={'tracedSVG'}
              src="../../images/investors/warren-adelman.png"
              alt="Warren Adelman Former CEO, GoDaddy"
              key={'warren-adelman'}
            />
          </div>
          <div className="col-md-4 col-12 mb-5 text-center d-flex justify-content-center mr-auto align-items-center">
            <StaticImage
              placeholder={'tracedSVG'}
              src="../../images/investors/todd-vernon-logo.png"
              alt="Todd Vernon Founder & Former CEO, VictorOps"
              key={'todd-vernon'}
            />
          </div>
        </div>
      </article>
    </section>
  );
};

export default InvestorsBlock;
