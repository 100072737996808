import React from 'react';
import {
  defaultDescription,
  defaultHeadline,
  defaultImage,
  defaultSubHeadline,
  GetInTouchBannerTestId,
} from './constants';

export interface Props {
  image?: string;
  headline?: React.ReactNode;
  subHeadline?: React.ReactNode;
  description?: React.ReactNode;
}

export const GetInTouchBanner: React.FC<Props> = ({
  headline = defaultHeadline,
  subHeadline = defaultSubHeadline,
  image = defaultImage,
  description = defaultDescription,
}) => {
  return (
    <section data-testid={GetInTouchBannerTestId} className="bg-primary container-fluid py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 text-center text-md-right">
            {image && <img src={image} alt="Stackhawk Logo" />}
          </div>
          <div className="col-12 col-md-6 text-center text-md-left">
            {headline && <h3 className="text-white font-weight-bold">{headline}</h3>}
            {subHeadline && <h4 className="text-white">{subHeadline}</h4>}
            {description && <p>{description}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouchBanner;
