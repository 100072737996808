import { ITeamMember } from './index';

export const defaultSubHeadline =
  'With decades of combined experience in security and DevOps, our team knows what it takes to build great dev tools.';

export const defaultHeadline = 'Meet the team';
export const meetTheTeamTestId = 'meet-the-team';
export const teamMembers: ITeamMember[] = [
  {
    name: 'Joni Klippert',
    position: 'CEO, Co-Founder',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/4M0SPV7IEAwu9nEUFXPZ5X/cc6fb97ef6e508be5a2f480751268bda/joni-klippert_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/4M0SPV7IEAwu9nEUFXPZ5X/cc6fb97ef6e508be5a2f480751268bda/joni-klippert_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/4M0SPV7IEAwu9nEUFXPZ5X/cc6fb97ef6e508be5a2f480751268bda/joni-klippert_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/4M0SPV7IEAwu9nEUFXPZ5X/cc6fb97ef6e508be5a2f480751268bda/joni-klippert_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/4M0SPV7IEAwu9nEUFXPZ5X/cc6fb97ef6e508be5a2f480751268bda/joni-klippert_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/4M0SPV7IEAwu9nEUFXPZ5X/cc6fb97ef6e508be5a2f480751268bda/joni-klippert_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/4M0SPV7IEAwu9nEUFXPZ5X/cc6fb97ef6e508be5a2f480751268bda/joni-klippert_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABNhJREFUOMudlM1vnOUVxX/P8z5+3/l+7Zmx49gVGKSEQkxi3KbgLpq2FAUQ/wEblBKoxIYFqN0QQJHaIiIqFVIlIemii3RR2JQ2KI2qIEiiJkpqArabENyPOLEziWfssT2eeT+e53bhhEpVWqoe6eyujs65V/covgRj+w8NeyKvOQVK6x+e3Llj4r/Nq/8odOBQ1UPtGsA+e6ekfj4IuOR0POPYJ7D7o6efmv+fBLfuezvQSj0TpsnLD7WuV8LWAoVsgf6+9TRWVvkwCLmAqRvjvSrIgRPffyq6reA39u5XotVjTuR1227f9+3ZizA3w7qwm4e2PkilmGWh1aZVKPPalQaLXhfZTDCltXoxFff+mad3yBeCo2/+YpNSak8rjre3o0RtXJpntPY5j9y3kc33D5Ov9OLniiwv1Tl5dpzjzueIzYEIQeBLEHQdVcILp3fumDQjb/z8rXYn2rkSRT5K4WmPgcZVVJwSdHWhPY0GtPEJu3sJcxmGGg2syaMQ2p1ItTqdR4uZ4LsP7j/4tomi+LnF9ipiDMYzBM7S1bjO7MISUWKxSYJtt2grQ9Tp0JPPk5u9SrnouIEG7RArLLZW/UI285yWmzsUgRRF2F4hWllivr7A8dPj/Prd91hpNpk++yd+d+xDqmGJbi/DeqNRWuGUwum1UzjAOEAAQdBAMV4Fl9LfHTLQ10//un6aHcfdo2MMNuqEpRzfHB3hp9NNnKQApIBSCgEMIjgRBLAIPWkH42miTsLDm79KI4pprbTJBD6mFAIJ79SaNKzDobhlKAEsoK0IqRNSEUSEoovJ5fPUl5pM/OUCH505x3KjQXNhiTMnPuDK3y9xNhIEBSgc/4oLoJ1z3KJyjlASCrk8V5tN/np9liv1JhdnrrHaTqncsYEEy4XI4USwCLcMuZtJNU7ACdo5lLVk0w5aWYqlPO+dm+Ku9RW6gwJ2ucPQ3V/hih9ywymsgNxM5RASERIE7UQQcYgTTBrjuwSjFUOD65i+XufEpxP0VwMKZZ/lhXmGwhL3t5s4m665kzWX3KRX3PadV9pRDEpRdQlbOjdAhJ6eCsbTzF2bA5vipRFdmYBSXz8LH3/C0UO/Qvp6ISyROsFzlkyXwaTOgXM4Z8lJjBIhG/hUevtAGwbCIoPr1xGWe8gWSlhlOPzbPyC1Gu2DB/FGtsC2bVAoIM5hrDhSa3FTE8hyDW/zINnAUMgG9FXLdPseY1//GtWBO9GBz49e38ulv/0DmyZYZ/HOf4KbmITRB+B7D6Nzvr+3cHEydsePESar+L6hy3gE2bXnz+XzFHuqzMxd48kXd/PO+38ktQlaeygFaZriKUVh5nLsnzq1VwHcsWVkkzi7Z9u9g9vHhsqqXK5iMkXmrs0TRTFBUKI2W+ON3/weEcFaizEGJ0Kpr1eKvb1H0zR54fL58UkNcPn8x5MCjw9v2vBE8a6NU7pYJo4tNu7gRPAyWS7VlxARkLXOy3V3M3DPPVP5SvkJpb3HL58fn7xtYx/+5U+CdmSfTTrprnh+rrK0uML4dI1Tf55kcbmFn81S7Out+/nsq8b4Bz47ffL2jf3v+PErz1eLxcJLKwuLPzj87jF/eqZGz8BAnC2V9iGy+/Nzp+f5f/CzPbuGv/XY9iNDI6NHNmwdG/6y+X8Ch291UeGZF5gAAAAASUVORK5CYII=',
      },
    },
  },
  {
    name: 'Ryan Severns',
    position: 'COO, Co-Founder',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/4GVTEwG3Jkx7GXki2mvAmE/7d1ee86f813e1af1c5e96a9352ea9782/ryan-serverns_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/4GVTEwG3Jkx7GXki2mvAmE/7d1ee86f813e1af1c5e96a9352ea9782/ryan-serverns_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/4GVTEwG3Jkx7GXki2mvAmE/7d1ee86f813e1af1c5e96a9352ea9782/ryan-serverns_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/4GVTEwG3Jkx7GXki2mvAmE/7d1ee86f813e1af1c5e96a9352ea9782/ryan-serverns_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/4GVTEwG3Jkx7GXki2mvAmE/7d1ee86f813e1af1c5e96a9352ea9782/ryan-serverns_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/4GVTEwG3Jkx7GXki2mvAmE/7d1ee86f813e1af1c5e96a9352ea9782/ryan-serverns_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/4GVTEwG3Jkx7GXki2mvAmE/7d1ee86f813e1af1c5e96a9352ea9782/ryan-serverns_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABKFJREFUGBmFwWtolXUcwPHv7//czlW3M+ec28y08p5WkmRUo5uomJkUEfRSC6J3qSBkifSi7IUvCqRAyrKSNPFFLYnKtDSoyNxceb/PrJ3NrXN2zvOc5/n/UvFFhJfPR7iB9s2fTQVeFwQxsuKbJx/v4jqEa2jfvHWEYFZFcfxcNYx8ESGdCiLPd9cLsubrxY/1chXC/9z70aeBEVlqVV8ZOHq0YWDvD0QnjpNuaqJuwSL8xpFkg6DoOs5qRN/56okFIf8hXDFz48fiGJkryNqwFk8unz5Nz7q1aLVC/fDhBIGPyWRwFz1NbsIkfNcjHfjdYmRZDB3fLpqnXCRcNOO9D6aIyJu12M4J40RcYyhu3ID3+36mjRtDkEqRz2Q4ebaH7j97aVn+Kt6oZlQg5Xvqe94O0Jd2Lpp/QKZteP+tWpwsKYc1X4wh5bp4IpTXrGTFwnbmzJ5FofUmgtxwLpw/yZYvdrBu/znyS1/kMlUQIeV7keead001il7oLw/5NVVEBMcY6P2bu8a3MaWtmcALcEwAcUyhqZl5D93PWCxqLWoENQYFBsPQj2P7gqsKioCAEQEV4v5+6rIZTvVXyJdCshYG+0u4nuLYGA+LG8fEjgsCFjAYrAhujCCAQQBBEAK1GIFfDh7j6KmzzB8q8fOvv6G5YTxwx620FfIUbQLGYFWpiXKJCrgWSAADiIICTdkMhWyWhXdOoRIqaVwWPPwgVY1x3ZhZ48byUyZLCIgIBiEBrAhGVFEgQVHAqlLN5GgeVofnZuk8egaTKVCLHLygnky+kcZ77qNiDAkQocQqWIUYMBalpooqqAIKfYWRDOVy7Or6nUqidB08SMVL033oCBf+6uFDTZEIqAFEqAFWuMxVVYwqVpWEBIvDJSdz9dzjKjkvx9Tbp5OvyzHea+FIaZBO64BwmQOoQGxBFYy1FmsTxFpia4ltglXLvqab+ePceXDARhEGS7la4rj1sICjIApGwQd8ARFwjVpsYlFjcazFmoREDYnCYKXKmcEBWioX6O0pE0Vl2ka2IJEigKNcYRFVRBXjJ5a0WlxNEGtxVEmikPrD+2jI5zjW08Oerk7CWpkpEydyRxDxTP8fuAMD+Cgeig94Cp4qrqsW3yY41mCqJaoHuhnau4e501sotN7EiPp6pk+aQOuYVjzHAc+j2nWI2t7tMHEimdmzMYUCqGIAN+v5b/vKkoFdu/3yvn0kYYgD/Hj4HONGt3Lb6FEMT6fIBj5xXGNP1wm27e5ERIj372ews5PUhAmMWLggcp30u86fHZ9/0dLUvC1V7BsnMN5GsRhjGAwTWhvruHvqLQRBQCaTZudvB3lt05ckVlHAJpZsQ73mfW8HZ84s/m7lsveFK6Y/Ol8E5tpabW2p2Dc5/KeEIIwd3cjoxgZO/1Wkd7CMMQ4IBPkcTjrVHWSyy1zX6fh+6yfKRcL/zHhkfuC4znO1SnVVqbfYUKtUcVwXYwxihFQ2S7ahUPQz6dXVoco7P3dsD/kP4Rpmzl04Ang5rlafLxf7fFUl39gYpYbl1ouYNXu2be7lKoQbaH/q2akobyTWIsLyXVs2dXEd/wKh6xZS7l4VYAAAAABJRU5ErkJggg==',
      },
    },
  },
  {
    name: 'Scott Gerlach',
    position: 'CSO, Co-Founder',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/5WXtqZ0PsrUoL2rz2v7ewa/91b8acae47213e796945e4228987c364/scott-gerlach_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/5WXtqZ0PsrUoL2rz2v7ewa/91b8acae47213e796945e4228987c364/scott-gerlach_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/5WXtqZ0PsrUoL2rz2v7ewa/91b8acae47213e796945e4228987c364/scott-gerlach_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/5WXtqZ0PsrUoL2rz2v7ewa/91b8acae47213e796945e4228987c364/scott-gerlach_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/5WXtqZ0PsrUoL2rz2v7ewa/91b8acae47213e796945e4228987c364/scott-gerlach_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/5WXtqZ0PsrUoL2rz2v7ewa/91b8acae47213e796945e4228987c364/scott-gerlach_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/5WXtqZ0PsrUoL2rz2v7ewa/91b8acae47213e796945e4228987c364/scott-gerlach_2x-1-ow5g5fs0er3j9gfu6l1v9s35oyob7u8unjuhurnhq8.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABJ9JREFUGBmFwUlsVHUcwPHv7//evM68DjNdpu3UIpa20mIVcYsJapASQA6auGu8GjXx6nISYzwpHI1pMGoMUTxxIrKEFCIxEj1ggBaiDFjAbnYZptR56/9nMZoYo/j5CP9j8569t1v0PUHAyJsjLzx3hhsQ/sPQnr0lI+wI4uTlxSD0BMH3c1FDxhsWkXdHnn9yln8h/MPDn33eICIvJda+LRfOt+Z+GsXEIRqGRJ2rqA/ehVNqm8u4zjvA7qPPPhHyN8KfNnzyqRhxtoPsrEfRbenlcRoP7KPFc+np7CTjZYiThPGlOlc2PYa0l/Gz3pgReV3hwLFnn1CWCcvu3/3xoBizK4qTbfU4EUkT/L0fs+GmDh7fMkT/mgG8/ArSOOK7706w5+QZxjY9hiD4XkYbvMwhRV87/txTo859wx99EEbR7tpvQX+aWjHGIJcvsnJynEc33E9/Ty8t5S78ljb8xhydpQKVs2NcbmwhzPnEaSr1KO4zIi/2PPNM2QRh9Gp1ccmz1oIIVgSCgBV+I01NLRhxsanFRhGqkPEL3FxuJ5vEGAyIYIFaPfCCJHnVTVVJRXAFEBARTCZDLVCOnjzN7G8RqxcDnJzPXLWG6+W4OBsi7SFGBBCusyJYDC7LFAGFVMFFKInBcw1JHDA1M8XR70+xfqAPz3W4tXsVve0FJpuKzCGgggAGEAFX1WJUSVCMKkYhLXVQyrqs615Nc3OJ7tY2Btet52p1nlwxT5ufcNB2IFGKIijKHxRcFFIUUUjU4mpKtVCko9xBX1c7lR8rVCYnWEFAsdBMV/dNXMtCZSolQrEoqmAABVxQHFVQpUEVo0rqCFNNJcYuVPj2+2/ovbmHyxOTjJ49SZwVxoqdhNqAAg6gCqmCVcUVq1hryVgL1uCoklHl52KJjnMnWNffQ//KPjpXdrG0VKYeBhxesjgGRMGqoiigXOeKtTg2RawgqcEYC2IJFmtcmp4j9GJMfYnFq9MYP4NbWsVU3sWqRVFAUSyKYlVxTaq4qcUxFldS6j9dZO7YUbaVfRr9POPz01SXJmmcW6BnzVqG7uzk6V9/4cOZGK9nNYIiLLOKUYuLWoyF5MoEc8ePE125hE1TJhq66CuXMcbgeS7r+tdw9x1ryedzhKfOM//FfrIDAxQ3D+E0FUEtjlpcqlUWRo4RXaigNgUxGAdOj09z70Afg73dJGLo676FQr6B8xPTDO87wnXBuXOElQqFBx8gd89dZLIeTksm2yaLtfVRve6goICIwVo4deEKkbUYY5ipXuX4D2d5/8tDXIti/pJGMcGlS2TnF6Ji96phYdnAQ0ODqrpraX5hW216WlRBWCYCyjJFxGAcB8fLAIIYQYzBb27SXKFwSBzntbGRg6PCn9Zu3Cqo3Z6E4c5r8wu31as1/iCCCCggIhhjEMfQ2NxEvrV1TFzndZQDZ458pSwT/mHtxi0NIvJyXK/vqM3Mtsb1OqqKKiCQzecpdLTNZfON74DsPnV4f8jfCP9h8OGtJZC3gsXFV2ozv3qqyoq2UuQ3Nw0D746OHJzlXwj/445Nj9ye2vR9tQrCG2e/PnKGG/gdHvkjuniVo84AAAAASUVORK5CYII=',
      },
    },
  },
  {
    name: 'Simon Bennetts',
    position: 'Distinguished Engineer',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/2Udd8xty1m6auFEtWdc0hR/67988f1183013388b94547ede881e8e3/simon-bennetts_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/2Udd8xty1m6auFEtWdc0hR/67988f1183013388b94547ede881e8e3/simon-bennetts_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/2Udd8xty1m6auFEtWdc0hR/67988f1183013388b94547ede881e8e3/simon-bennetts_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/2Udd8xty1m6auFEtWdc0hR/67988f1183013388b94547ede881e8e3/simon-bennetts_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/2Udd8xty1m6auFEtWdc0hR/67988f1183013388b94547ede881e8e3/simon-bennetts_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/2Udd8xty1m6auFEtWdc0hR/67988f1183013388b94547ede881e8e3/simon-bennetts_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/2Udd8xty1m6auFEtWdc0hR/67988f1183013388b94547ede881e8e3/simon-bennetts_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABFBJREFUOMuVlVuIlVUUx39r7e/7zjlzPXPzks4k4zg2HiM1RZDKJxWfCsKQgh7sYmD1pPWUIT4EKkXlw2AQgdBDVkQEatCD1qSEU1JpYdpI4mVuOs7RmTnf+b69ejinKcHMNqyXffnt/1pr77WE/xhP9Z1YbNguAVORV/evWv7znfbLv4K+7W8VbHvi/eY4SSMBsmEQR871irDz/ZXLRu4K+OSx/owIz3tvr5eTtMV7QwUEQYHAKZnAjQaqO1TY17tiSem2wI3H+0WF9Xh2x2m6KE0NJ6CAiKDINFiAyDmi0J12ItvE5OC7yxfbNHDj8f4C2J6yt3Vp4kUQXLnEzLM/MGdskOTqVXJtbQzf08lQRwFxAQqoQOicRU4Pq8jWN5cWTskTx0/sTb09F6c+AnAI6lNmfvgOzyztojFXy7XxSVLz3PRT7B8YJ3x8Ey6TnVbsRAgDjQPV9zT2tmUiSSMPIAIiyOULrJ5Zy6x8PTOamunpnEd3+1y65s2lUB5j5PDnOFWcKoFTVAUzIkG2qAFWDadQAbqhS/S0zyITZYgaG8g058nm68howNLCfDKjl1FVnDqcKIE6AnWVCwwwudXqKZN1AYELUXVY6hFRAlWyUUiQpKgqKqBVl1WEQIXAVxV6KosgNIlxcXic4dEperoDgijHtWIR8yVqczU0ZyNUBBFQqYAdgqoSmAmpgYjhAcFobspzY/A8fwwOkRVPQ10D3/z4G41NeRZ0z+aRllr6pPKcpKpOhYpKw/BGFeoRHBPZGrrnzqJn3nySqQnCMMvDy5aTxEUUob21uRJ3EapOVZIpgnoMPw01PJ6iRtTlGrA4JoxqEHPkG2rp6OzCBREXcvWYCH8l1KapEHgzvBmCgRdEjJH6Ji6d+56TZ39naHAMZ1DoaqdnwUJuasKvHQ+QYtO/piJK8ECQmsebVacVUhjJ1nHq+hjjxUmWdnRBGNI/8AthfYZsvonhxjZSb6CVVFaGx5mi3huJeXzVUjPKpnw3awET6RTXkyKTcZGEhKFrRYrlMjfDDIkZSfVsUj2XGBWgTz2J2S3QM4tWMhTVEVOmWL5BcapEECr3NefoPHqIicuD/4Aa5aqnbsGmZ9uc6JLEe+fl7/JjqiyRmKsDA1wZK7Kip5NVDxY4c2GUDz7q50rfSSQQcvfOQV1AqBqrSK8ArDlyrICwJ/Z+Xcl70bPnka9P8FBQ5LFlM5nd2sxU7DnQN8DHR88hLsC5gCAKaLm/2zqefvRwbkbr1rcK3aem6+GaI8dEVdZz8vTuic++WlSemMT5lNULm5hKhL6fLjIRC2EUAUa2sY66lvzpqKZ2W64xOnig9w27bcVeu+HFDGqb01KyffzK1ZbS9Rtghgsq/zrMhdS25kddNtoBsu/LT/aW7qqnrN34cqul9lpp/MYLkyPjkQB1bU1xpjbba8bOQ5/uHflfTWo6FBteWqyeXWrgnL7yxYG379j1/gQ9ceduJgwEDgAAAABJRU5ErkJggg==',
      },
    },
  },
  {
    name: 'Nick Teets',
    position: 'Front End Engineer',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/SXArH9Q59BNr2rqGe80xb/11f24c45cb76eb48460bdeb92d6f9b69/nick-teets_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/SXArH9Q59BNr2rqGe80xb/11f24c45cb76eb48460bdeb92d6f9b69/nick-teets_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/SXArH9Q59BNr2rqGe80xb/11f24c45cb76eb48460bdeb92d6f9b69/nick-teets_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/SXArH9Q59BNr2rqGe80xb/11f24c45cb76eb48460bdeb92d6f9b69/nick-teets_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/SXArH9Q59BNr2rqGe80xb/11f24c45cb76eb48460bdeb92d6f9b69/nick-teets_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/SXArH9Q59BNr2rqGe80xb/11f24c45cb76eb48460bdeb92d6f9b69/nick-teets_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/SXArH9Q59BNr2rqGe80xb/11f24c45cb76eb48460bdeb92d6f9b69/nick-teets_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABG5JREFUGBmFwUtsVHUYxuHf+50znZkW2tJCsQWkMFKsFm1EFG8QjRJNUCHRhMSIutIFqIhGQmLUaASjLty6cIEhunGBImqMRglegEKhoFYNYKuW6/RCL9NO5/w/i9FAjJfnEf9jXcehnNAmiToTT73S0rKX/yD+xRMdHVWgpwM8FpJQbkAqjkpmtsXgmU2XX9bDPxB/s+5gR4y0GvcXE/d6dxg78iMD3V3UtC6kYuo04sjOmmkz8PpLzc0jXED8aW37AZm0RNJrpRAWujtnjx2lc+sWju/aRSY2WlpbmLVmPZlZjZiJyOxoJG0w9O7zl84PTBAT1rYfyAleTtxXJsFNJoZ+7eaLNY9QXipxZdNc6mpryDVMY39IcdHDj6PIOCc2w6SdiPUvzL+0TWv2t28O7o8m7lkQJpCMr5/dSLrzEOvuvo0brr2GqbMuJpXNsGfvHrZ09VO16n6QIRwhzFQyaaslITxdLCVZHExgEsIZOHyQW1qbmd1QT1mcJs5WodqZXLX4RiYd+4GRUycxCZMhCXeP3XnAmCABAklIotCbJzU2SsO0OvIjo5wdK5IkCX3HeygMDTG9ejKpdAYzYRImIQmTMAkkIQmXcMDSaTxOkSQJXSfzfPrlNwwP5OnY+Skj/b2UxTHVsWEISUhCEkjEjgBHgADJyFZWc/W8OVSVZ2hunM3xnlOomLB46c2kp9RwX1majyvK+UHiQhLEuIOEI/4gcMGiRYuIkjHKozKS4hjjOJnCKOPRIKGhkZOWBoEQf5GEBXeCO4G/CCG67lnNCYs4lj/Fr4Mj9PX2cbq3j75T3WxLIvoV4ZwnhBCWuBPcAccBF5gB2SxnpkxnKEloqKzGBwpMr6tnbFIVv0UZHMcRzgRBAAKOBQ+EEAjuuDvujhCGyNZMoe27HymkjezUSvJJkROjJUpRBIhzXOCABEjEITiJOxYCkjAgOIwPDxH352mYWkvb9510nc5z0/WLqW5diLtA4IA4T0Ds7oTgJCEgE/3dv3Dkve10btvG8gWNPHjHbcybk+PimTOZ09TEwPAg7W99wCUrljO54SKc8xyIgztJaZzT+/bz846POdG2j5AEwNne9hPzck08tOJ2ioVRUpkMn+9qo/Ojz/h2xyfMuuk6Fty7gtpcI+4ggVbt+HDzd2+8+WhPW3s2GU8Q4hz3gFkEntCSa2TBJbPpHyrw1eEjEKVISkUcodhYsvEJ5l5/Tclgq5hw2ZJlOVn0cuHs4MrhM72WlMYBEUUxkgBhAotiojiFEI6DYFJtDXXNTTuXbnx8/XO5XJv4U8uty2WupaXx4qsjfQMLR/r6kQzJMDMsipAMyZAgM3kSFbU1R8uymQ0O7+7etjUwQfzNFcvuigmsHh8dfbHQO1Afh4hiUiQBKmbUQ6FAeUXFYFl5ZhPo9T3b3xnhAuJftC5bUZmNyzaMBR4b7jlZHtkY1TPqSzOqZmzpHsw/s/v9t3v4B+J/XH3nqtz8uXM21VRXTt+97+CTe7a/s5f/8DvGydQPBke7LAAAAABJRU5ErkJggg==',
      },
    },
  },
  {
    name: 'Sam Volin',
    position: 'Full Stack Engineer',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/4W4mFKf6Zb9T2vX0hPHMZs/9b87b378260fbccc09bd62216cb2939d/sam-volin_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/4W4mFKf6Zb9T2vX0hPHMZs/9b87b378260fbccc09bd62216cb2939d/sam-volin_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/4W4mFKf6Zb9T2vX0hPHMZs/9b87b378260fbccc09bd62216cb2939d/sam-volin_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/4W4mFKf6Zb9T2vX0hPHMZs/9b87b378260fbccc09bd62216cb2939d/sam-volin_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/4W4mFKf6Zb9T2vX0hPHMZs/9b87b378260fbccc09bd62216cb2939d/sam-volin_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/4W4mFKf6Zb9T2vX0hPHMZs/9b87b378260fbccc09bd62216cb2939d/sam-volin_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/4W4mFKf6Zb9T2vX0hPHMZs/9b87b378260fbccc09bd62216cb2939d/sam-volin_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABJNJREFUOMuVlVuMnVMUx39r7e/7zjlzOTNjDh0905Yxqd5cglRoJS4NGiTa0goeiLg9eHN5QkTiAYkHRNMi6pKISzwhRZpOi4SHiktHK71rp4w5Y0bPnMt32cvDORoPVK2HvbOTvf/7//+vvdcS/iOWb3pzSWrytKqaU31k++3rfjjRfvlXoNfeKCnyWCNN7602k0hF6ewoxLlctB6RJ7fdsmbipACXvfJazpB7Mu8fb8Zxf5IZOIeKw6sQhgEdhXzFOfcEwobta1c3/xHwgvUbJBS30kSeqTebi5IkReOYeN8+7I8/COeeQTg0hFcFFXJhSEc+GnWqD2F8PLJ2lR0HXPzCS4sj1WfjOLmmmaTiREnHx6l+8B5BvY5Tpd6o4RYupm/1zbiuLkAQgUIUWj4KN4vIgyM3r9op5z33/At1n93daMaRiuJcgFNh4tWXyY4cptzXxdUXn8Pw3AG+2DHKp7WA0++6D5GWOMNAhM58FOfCYKPrvWLFh1O1mlNRRB3qFMkyft/8EVcunsdT99/Equuu4sLzz2fF0iUcHf2R3WE3UV/puGspkKSJc6pLtXVLK3zb0WRsjHKxkztvuJx5Zw5T6CkTFgfoKpW54/rLSL78/DizVCAFrJ2OwAR8eyHtIRn/le4o4Js9h9kzmbL62n40ivlwyzZ6tAZHDpGaoar49rnYWsBqZpgZ3tpUDbJ6nXwUsXesQnXmGD999xWN3/YRSEplqsrCoTlkKmTSVieCiOARAvMG3pOZ/WUxfXMHWZBUuPXqZfSfNkBzepq+Yj8rLu4nLwlzygM8LIpZy6y/5pZk70m9x/uM0CveG8weZN70YXqLJYphgWoxRIM8s7o7idOUrsEzyI5BhuHb7okZmRkBZqj3LZbeE3jPdBBxsFRm/6GDHBBl79gE5y48m9NP64W0wft2Ct48Jh6sLbttW2Bm4FugmnlQj3rPjr4B5h8dpUOEBcUSZULSepU9cczWsITiUQSzlk3ewGOo94Z4j/MetRaY+gwROHj0FyyKqHc6JnPGeKPJyFSCazFBvEfNCMwIMZwZARjOG6E3XBvYKlM0do/SU8zz9c5dzBmYTe/kFGQNcj2zSJMx3KxZiLZ/i7USE5gnCK3lnaUZOvk7fu9ekv0HOLUnx8DyRZSKHVSmZ8j5iAsWncXnu46Q/2wHDJbJFszHl2cjocO330jQGUUv5pqVu5Odu6L0twnSNMWpY7waM1VPuGh4NoV8gVN6utlfqfHJt4cQAzt0GA4cxPX24C5dSm54KA4Dt9H9suWzj4YHz/ygEEVD6txZWRyLiqIasPPnCtUEmq7AyI9jvD3yPbV6gkgrGYiQ7yhYsVbbnA/DNdsfuH/T8Xp4yY3rJHDByiSOn5mpTC6qTx8DDO89hhGFEWYQBiHqHPnuLvK9PaMSuIcQ+XjbO5vsHyv28tW35kTk3rjeeKw6UelPGw28N5wqIOQ6O+g+tVQJC/knzGzD1nc2NU+qp1y25raSmT3arM7cN1OZjFQdnf19cb67e73Ak1vffX3ifzWpvzFegtnTIIjKw9vff+uEXe9P+yAoPR4rdKAAAAAASUVORK5CYII=',
      },
    },
  },
  {
    name: 'Adam Baldwin',
    position: 'Full Stack Engineer',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/6ZOh7UI1rz3DQoHDaDaCpy/3be2350e6a1e448610787b9cb93da052/adam-baldwin_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/6ZOh7UI1rz3DQoHDaDaCpy/3be2350e6a1e448610787b9cb93da052/adam-baldwin_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/6ZOh7UI1rz3DQoHDaDaCpy/3be2350e6a1e448610787b9cb93da052/adam-baldwin_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/6ZOh7UI1rz3DQoHDaDaCpy/3be2350e6a1e448610787b9cb93da052/adam-baldwin_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/6ZOh7UI1rz3DQoHDaDaCpy/3be2350e6a1e448610787b9cb93da052/adam-baldwin_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/6ZOh7UI1rz3DQoHDaDaCpy/3be2350e6a1e448610787b9cb93da052/adam-baldwin_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/6ZOh7UI1rz3DQoHDaDaCpy/3be2350e6a1e448610787b9cb93da052/adam-baldwin_2x-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0tGPNhBwAABEBJREFUOMud1VlQW1UYB3B44knHKi0UCHvAEoZCgAJiK1PKkgSklBQwCQESiEVZKpVFLK2gFiqjFAjQBZVSW6ZMqVBWLYKEPSGUJFAX9KE+8cCMoz4weeHvdxMIibbTTu/Mf+7c5fzOd8+591w7u6ds+YuGQMowZUihNQTaPe9GjR0pzTK1ziiZewAmMo3eSOeamGvPDOUv6h2omiK5Rr8hnVuGePaBTaTzy8ija4QWURyeCNFN9gTxCVplGolnl8zIzBKEA2NI6exG+vC4Bc5eWAZ1vkooX7FosLetSqPn5GkMIznzui0G2Gn0JiGRAh6C/T3A9XXBIY4XYopKTJ1IqHomuWrdVr7WMEIwx4yp9crcBZ2RAUR0o2gbTO7owkG2G44EsJAWFYCCxAjwQnwQ5ucGwaVWC8gki7I9vko7GT2eaHrJgu0k/HAksuPC0FMlx6Puz/HX/S6s3WrAp1IejkZzTZ1ao8wxobDLpQPRlNYGE/aPIvKAOzor5Vi5eh5/jnyJTfUANmd7sX63CRfzU5HWOwQxA1mhchvQqsq0e9/hcKAXrpfJsNx2Fht97dic7MHm2E2s9zaj5d10CHv6IZ7XmWMBDdbgbpWS+1Pgh72Ka6claJUfR5tcCMPlGkzWnUa7IhXVYgEymRnfAbdRM8hMhkprgxYOjKJJfgLDte9gprYAyw3l0NWX4jdlNZYby6BqrEDpD1O2IMUCvqVaNGVnLLMn5vBtTTHuVuVj/fpF/EHVqWtLsNZyDv/0teHh13WoGFf9D5QxYI4VaEGp0sLu27hXW4yfaVI0n72PmY/fw/yFUqxdO4+x2+bX5rFgNlWU+aMGmZNWKA2B/E4/vqpQ4FGfEqr6cjzsaoe2tR6/dzfg0mCvBZGY9nozuEiglKpJn1igqJFhBR9Jz8DlkiwM1pfgp45arHxxFr8oP8Jk84eQTcw+vkJb0Bzh99OIPncBL+91QqX4OOpyUlB+8hg6y/JwpViCW1WncOLmnd0X+r9gFlVzcnweaaMqRJVWwjOIi/3untizdx9cXFxxo+JtKE9l4kqhCDfOyPBNuQJBomzTOEu2X+xdcIUmZVqrFLRcNR6IiYObNxsuHt6m/StU4QsvvoSMuBj0VpdA1fABOkpy4e3uDsf9rmC/EYtjjW3mT3DBVJ1RsbSqNC0QhwTJnNBE3siBiKgtlg8bLAKdXamhswv2OO5DXUEufu1uQ41UCCeWJz2BF3XqC5avH8Klsi3pmIpWmxWOzRIWxhPYRwiS+dy4hFV/bjg8/QLA8vGDs5sHAjmB+HtmCGeESXAnhHkCdkgoQuITVqkdPzo1zf6JC210qtAhnJ9UzI2N32AHhZhQJ1cWBhs/QQiHA/ZBLoKPxm6EC5KKCHR45l9BRHKKIw1FU3BMrNHN1x/pvDhwXnvdyJwL4wscn/tnRXBgaAJvmElwfOJT/3r/Ap7CqtnAjPopAAAAAElFTkSuQmCC',
      },
    },
  },
  {
    name: 'Aaron K. White',
    position: 'Head of Design and User Experience',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/1BJaKI4aLO2nwAVDCyRTwT/9471ead38db7e382d462390ae5450132/aaron-k-white_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/1BJaKI4aLO2nwAVDCyRTwT/9471ead38db7e382d462390ae5450132/aaron-k-white_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/1BJaKI4aLO2nwAVDCyRTwT/9471ead38db7e382d462390ae5450132/aaron-k-white_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/1BJaKI4aLO2nwAVDCyRTwT/9471ead38db7e382d462390ae5450132/aaron-k-white_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/1BJaKI4aLO2nwAVDCyRTwT/9471ead38db7e382d462390ae5450132/aaron-k-white_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/1BJaKI4aLO2nwAVDCyRTwT/9471ead38db7e382d462390ae5450132/aaron-k-white_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/1BJaKI4aLO2nwAVDCyRTwT/9471ead38db7e382d462390ae5450132/aaron-k-white_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0tGPNhBwAABMhJREFUGBmFwW1oXfUdwPHv73/+5+He3JPc5uYmbdomaZKmNnYqDH2jzoc3otgKC9sEhb2yVdzezY0xZpG+0w0GblIqPg8p+kIUH1DYmExtSzuhXRJbbG2ytI1tmqQ3N82595x7/r8lbC9EfPh8hO9xwysHd6jypBFRa+Q3Rx/46TjfQfgWN/z1YJfA42nW2pM0ssAzQlwopFHg7zfCvo9+9uPLfAPha659+dXQE9ntnNubNNKK5uAZgxFABGMtcSGcF897QkQOHP7JfU2+Qvi/rS+8IsaYu0X1qbSZjeYth288pL5E69Qk2sqwmweQgUGcQBj4xGEwaYx5zAnvHRnbpawSVm157sVrVcwfsiy7q5W2JBQP37NwcZb8pf346mgrFonjdrKhEa7ccidpoYgItIWBFnz/fRF+dXhs14T0H3j+z408f6jeTINIDAXPIsbDqJI+80duin38MEJsgB8EtNIGswMjXLj1bgRQUUSgFPppwdpnbSNLH11MmgSexTNCKgYjQnRuip//YIDbdwzTv2EDUWc3UfdmQrfChydO8AvNUc9iEQRHrZEGEsmjRlklgieCYMgBI8LmqzW2DfbTWanS1lGmZ+MA1a4e4nKVW7dtoS9PaBlBjQExqAhrjGBY44ngWCMgQo/kJKmSqwVTJGukuDRDnRBEMcUwQMTQEnBiQAxOBJsDjlUKmQhGoONqneHmElHbOj49eZZPz8yyqT3g5h/dwj/+eYTejRsoF6qogojgAEHIRbAIKKCA8D+9K3WiWo3B0UEqQ/3EXb2cmzqNZwyj20YoxRE/rKX8jRCDYEXJMYgIFgVVyBVSAYuwXIzxjCWpp1S7OzBiWJlfggz6ql0EbUVOJwtoEzIUYY2SiWBVFVVHroqq4qnyn2LMpXIXUxcu0hvHLNa/YPrCHO3jn9ERGsqdHSy4dgSPNQ0UC+QKBlVQxakSKauUFBjvXM+V5Co1bXElSejv30SpWETiIpcl55CzoKCABQRwgFXnUFVa6iiq4qkieYvW+RlqScLE9Ay3XT9KwZYod1XxSh77Pr9Ibg0WUFVUFQVyVQzqEKc4dWSa4zklPH4K/nWM9eV2pi7M8snE52gU4nzL1Mx53qk5PFUCwAcEUFWMKtZzDnE5xhmyvEU6Xyc4MU05LlNpL9FMU45NnmT8zDTdlTI37hgmffVj7CO7Uc8QArlCpooP2KLv/wUxD+Vz9cA7fJJ1WmDl0jxzW7ex3BK2Dw7Qsa5MtaeHgU3ryVbq9PhlkrcmSDcUyUZ6ycohnVGY+p551qu99867Q9fd/Mb2pG3QzC0N+UGbLC98yeLleWayAqO9Ja7Z0kcpiqhWOzk+Oc3xWSWZXSJKPdYtNrVSu/q+GegaOzq28yXDqn8/9buJpFK5p6O9dG/fNcOT1S0j2LidU5+d4rcH3sZ2VKh0V5hfznjugykW8oDK1iE2jQ5PFvP0XtO58Z5DYzsnWCV8zc4/vRsufPj3PX5UevziF6crVxbmGO3r4ZcP3sHBQwucnT6HHwTz3du3P5FPTR9487W9Tb5C+BYP7D3YlSwu/L6+3Hg4dya4dO4McVsx3Xjd9fvTmfP73nrxsct8A+F73L/n6R01kz0ZmgK+kV+//vTD43yH/wKddgVN+eXfRAAAAABJRU5ErkJggg==',
      },
    },
  },
  {
    name: 'Lauren Boyer',
    position: 'Jr. Front End Engineer',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/57Blm7O5kxsAczaMG8iFDa/bc1766ffb1a0d03d38bf4e817f7bc6fd/lauren-boyer_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/57Blm7O5kxsAczaMG8iFDa/bc1766ffb1a0d03d38bf4e817f7bc6fd/lauren-boyer_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/57Blm7O5kxsAczaMG8iFDa/bc1766ffb1a0d03d38bf4e817f7bc6fd/lauren-boyer_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/57Blm7O5kxsAczaMG8iFDa/bc1766ffb1a0d03d38bf4e817f7bc6fd/lauren-boyer_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/57Blm7O5kxsAczaMG8iFDa/bc1766ffb1a0d03d38bf4e817f7bc6fd/lauren-boyer_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/57Blm7O5kxsAczaMG8iFDa/bc1766ffb1a0d03d38bf4e817f7bc6fd/lauren-boyer_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/57Blm7O5kxsAczaMG8iFDa/bc1766ffb1a0d03d38bf4e817f7bc6fd/lauren-boyer_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0tGPNhBwAABLVJREFUGBmFwWtonfUdwPHv7/d/znnOOTlN0txq0iZNm9ZE0+mGMuqWVV8IG/hCZKBSwQvCxgb1grpWULFQqL5ww43txV6NiiDMbQzfWHHtEh3dqJemeoy0PccmGpvT2KQnPZfkPM/z/5moMBEvn4/wHX5TmBpSkYMq9KjIIwdGho/zLYRv8Ehhqk1gr8fux8ipQKAudk4Pqcjj+7dv+5ivIXzFQ4VCoMidwAFv1usNFAhUEVGcCoHTJSfylIo8+9jQ1jpfInzhgcmToqq7ROQZb1wjBgjEjTor0yWSs2fo2Lqd1BVXoYHDieBUS6qyT0X+9ujgoGeVsGrPickhJ/q0wS0mqIiiIiy9X6D0h6eJ58+T0oSrR4bp/eEY9Z/+HFpaUFGcCKoyISIP7du8+Q359VtvPQVyn4esU8WpQ1WIFhd5+8F76Q+Vse9fzZahbbggRWl6mtkwT3j3HtQ5RAQVQVVi4PnAe9sbmcc5hxMBEUA498pLZBpVbrhuFzfuGmPz8ChB9yYalxY5uP8xKm8cY/3On4AIiGBGIMJdaoDxfyKAGedf/zf5lla2DG5hOTISE/AxmbZOfnHvPVw4No43w8xYY6wR1FhlYGaYAQZRdYnK6SLXjl6JmWNyZpH33j5J8+ICJ/51hEyQI54ugXlEBAOEz6mZ4TEM8BjejKheZUM25PL+PrYN9rPrB1cSL9dJmk2ynZdRW1khXrgAlyoYxhoDDFADzMAbmIEBmskhYZZcOoXFMRlbwdeXCBS2b+0lSKWxOIbyOUBYY3wuAMPMwAxvRmKGZnJMLVYYn5xiU283xeIM+UyWpU8qREunmDhxkmYUIXMf4odHEUAQ1mjiDTPwgAEeMKdcdt1OzsyWiRLo7Ohg48AQYa6NfP9mXh5/jUa9hi/P4g0SA49hGBp7T2QJiRlmhplhqgztvhvvHB+eK1NfqdPZ3Uoq59j/2z9y8tRZlhs13EIZYY1hgAGBNyPxhjOPR1HWCC6dJbe+nXwuS625TOmjaTZpTKl4Cm+gYtSnz5DyHnXKGg8E3gwzw3sjMUPNuFgs8b/f/Z7WxQVablpHT1cX3X19ZLNpWGkQBo5s1lEsnmVH5QLJ+h4+IxCwKokiaqUZ5t98l+pHc8wVT1Obn2PeJ7w6s8ieHTvI5fPUqxU0SBM6o6+7HZfOc378MB033wGimIEb3b07e/q5F6+Z+c/x1NxUkUb5IrTkCeMmEcprR4/S0tHD9df/GL8S8Y/D46STKrlMmjCdIvtJmdJyjp6RbbEKz7nCoUOvrrt89IX2MNwYdrSONOt10TBDpqcLs4DMhgGOHpmgf0M3k3PzvDgxycZwmbjZoL11Hb0D/RRmGxMDYztvfWLL4J8cqxYKJxZvvuLav57pbhsPzH/PmnFflA4g8SSxR8MsR/77JsdmKlRrTTam6rTkU3QNDpXCto5fburK733ilttmWSV8xcjPbg/Upe4klzlQ/mCmVxuGipEfaEfOLxAHbQy3LVzqbYkOdrbmn33mL4frfInwDcZu/1VruX5pX7PRvL9WWcqRy9DV0RmnNXUoWTr3eOGVf37M1xC+w4/2PDlUq148GCXNDa4aP/zO3/98nG/xKV8kIIzAoofKAAAAAElFTkSuQmCC',
      },
    },
  },
  {
    name: 'KC Berg',
    position: 'Principal Architect',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/4ZFkibHgqfKaX2rgiuBD8P/139c19f9f5fa5c72497cf114121e6e47/kc-berg_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/4ZFkibHgqfKaX2rgiuBD8P/139c19f9f5fa5c72497cf114121e6e47/kc-berg_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/4ZFkibHgqfKaX2rgiuBD8P/139c19f9f5fa5c72497cf114121e6e47/kc-berg_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/4ZFkibHgqfKaX2rgiuBD8P/139c19f9f5fa5c72497cf114121e6e47/kc-berg_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/4ZFkibHgqfKaX2rgiuBD8P/139c19f9f5fa5c72497cf114121e6e47/kc-berg_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/4ZFkibHgqfKaX2rgiuBD8P/139c19f9f5fa5c72497cf114121e6e47/kc-berg_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/4ZFkibHgqfKaX2rgiuBD8P/139c19f9f5fa5c72497cf114121e6e47/kc-berg_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABJRJREFUOMuNlVlv1FUYxn/v+S+ztdMOnWmVKkNLKFhqI1GBuOOGGECMkW+A+AFAr8QQLty41MTInSSEBKJoNRU1KjGxLlFjoK2W6IVSpJ1OS5eZzvI/5/ViaokRl5Oc5OSckyfPuz2P8B/rplPv9NWUlwwQGu/Z4Sd2nP+3//JPD32n3s0KHKxGdt9ctR6KCM3xWC0R+K/7wuFvdz869b8Ae0+ejvkiT9WdPl+p1ttqTkEEJwICnu+RDsOi55tDIG+c2/VI9ZqA6068JYGR7XXllXI96o2sIxCD1uvYShkNQ0imiIxgFZKBT2sYjBgjB+qqg6O7tusyYP74yQ2+mCP1KNpWjawYMcjiIsHg2/TPXubWGzooW8vnVyr8cseD1Hp6QcAHkoGvSd8/U0P3/7xr+7CsPnbi1QVr91YiGxoxpDwfP4pYcfwouzua2dzTRf+6HkzgM1Mq8cKxk3y6+SGmbrmdQEFQHNAU+LW4Z456sZ2PvV8sVzw8Q8z4GOOR/W6IJ1OGu3t7WN/VTea6PKmOG8nk2ulbk2fshx8YXnszTiACagqlKPICkU3GIogYHAZjDIEI8dlpVratIJZI4YUJJJ5AghAvliK/upv7850ECs4YnBGMERCDE8EAyJ+lURARXBRRq1tC3yOMJ9AoQus1VAwaWXLJBM22jkFQEawISOPsKxBJg5mKICKYZDMXC0Uq5TJjv17irlv7mS5OM3apwPruVbTH42gQNNpEBRVQabSX/yczp1BRJaaKyebIFEr4xpBubaU4c4XOfJ6KF6M93USuLcdc5OEarYmootII1aCNC4dSQympw668kYQf4/EtW1gZi6HWkI4n2LBqNa3pDoYjIUJxTrGqqCoooIqPKoqCNnYcYSrbzkTk+G38d775aZSyg0KxSGtTilw6zel0O54nOBpAugSGKgZ1iHOIUwKneM7hA5NNTQx8/zUfj4xSXJjnp/Fx3hn6gqqp8CMenihmOXdXR88Xa8FafGNIOCGmQuAMv16/iu75Sfbcs4Vs8wpWdXdTrcxgTcA4AZ5zCOApWFXc1ZAdSWcJnSHlDHHrCMQym0pzYWKKibkFcvECk/NXmKxU2Pnwg3TUlTlVANxSDqvq8FXxA3VYdSTUEajFLZQpDH3B5a+G2LRxLaO/jXPOKamLBXZsvYuOzhu44/QgFybKJG7ZSJDN4lSpqhJXxW8Jg9daPW9vdXomXBgaojQ0hCstoApfjoXs2/EAzS0t5Lu6WLOuh8hGnP9+hNJ351kYGCDW1UXmzjvpvP22Gr53VAA2vXhkw8zg4JHS5Yltc5OTotYigHOOjkyaPQ/fx+aN/RCEvDnwIWe/PdfQSGtpbmvTVFvmjNfSun/kvbeGl+uz/p6tIpjt9UrllbnCVO/i7OxVydTGFBjjIb6PGEOyNU0qkxkR0QPiB4M/fvaR/kVglz3k3odiqNtXW6wcnJ+caqsuLjZgl0YzlkqSbs8VY8nkIVTfGP7kg2sr9jWAs6DPLc6Xnp4vFEKA5lyulmxJvw4cHvn0zP/zlL95zNZtfc7al5dYPjN69qN/db0/AHZEFf/T13j8AAAAAElFTkSuQmCC',
      },
    },
  },
  {
    name: 'Zachary Conger',
    position: 'Sr. DevOps Engineer',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/7dmHrDeTSTkxxigwcIZFnO/5036ff18d9e107c91ca90fea921cafe7/zach-conger_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/7dmHrDeTSTkxxigwcIZFnO/5036ff18d9e107c91ca90fea921cafe7/zach-conger_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/7dmHrDeTSTkxxigwcIZFnO/5036ff18d9e107c91ca90fea921cafe7/zach-conger_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/7dmHrDeTSTkxxigwcIZFnO/5036ff18d9e107c91ca90fea921cafe7/zach-conger_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/7dmHrDeTSTkxxigwcIZFnO/5036ff18d9e107c91ca90fea921cafe7/zach-conger_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/7dmHrDeTSTkxxigwcIZFnO/5036ff18d9e107c91ca90fea921cafe7/zach-conger_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/7dmHrDeTSTkxxigwcIZFnO/5036ff18d9e107c91ca90fea921cafe7/zach-conger_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABKdJREFUOMuNlVuInEUWx3+nvvoufZvuzlx6Jskk0U2MG6ObC96ffFhkdXENMYjiBcFsRHxxWS8vKsGndRdFEI0OqCiI4JssGxWT1bC7GoZkIySjURPDOMnoTOZiZnr666+76vjQjQ9udD1QT1X1q//516lTwv+Ja19/c6ODv4gIgTGP/Ov2HUd/br381MTVr7/RJ5jHm632rnqWRdYYirlclsTRHiPy5P4dN5/9RcCtr74WB2L+6L0+0Wi2elveE5gAEUGMEIUhhSSeCW2wW+GlD265uXle4OUjr4g15ndN4a/1NNvQch1QEBhEBBN0oQgiQhKG5JNozBjzEOje/dv/oD8AN704conB/C1tt69farVFMQQ2IAbyHx+AiXHalWVkSQ577XXYQqmjRoQktppE4buI/Hn/9puOyWXPvfBc0/mdadaK2gI2CCEICL2n9Pe3uDBb4PfXbEas5eiJCf45OYffdgeUqwjQQsEIpTjKcjYYkfVPP6vzjSaBtWgQYG1IYAJyBz/kt/Vv2Ln9RgaHlhPFEd63+PA/B3nm4BfMbrsLFVAFRRGgnEQY183bdXLAIBgjFBbPcdWlG0jCiDBXJuypkZSHuHLrFi4MQaWTsoigImiXYVVB5X+rJ58v4MWQZopXodVoMr/UoN109AyvxhsBBS+KAhZBxGC9gFMl7CoVAUFopCkHjoxx5MtxVvQdZu2qNXw2fpq162uIcyRAKt17VSVDaaNY0Y4PXTNAwahno7ZZPdjPxnXr+fT4pwz3VVh3wWrqrUXuvqKfmm/wsuQBCLpCFDB4D96TqeJUaanigGoSMdzbB/UF1vQPMlhbzsLEaQZKfVTyOSaTIg6hrZB1/VPAOOdoO4f3nkw96h3Oe45cvJkMz1LaYnruHF9PfEt1aBXp4hz/zkL+kTpc9/C2Km1VAIx4Be/Ae2LnEPWoV0711vhEYrBCKVckiYssLsyT+hYjS5a2V1CPUY/Rjm8tVQzqMd4jrgO13hOog+kpDo0e5qvJMwzVeogipbZqkBOTU0xjCVEiVawqAfoD1BqvBL4DDbzHOIcFogPvEc1Pczpn6O05Q9sInIbJiTMUx5c4t+lKpFtuXjvDqGKMgvVK2FUnWZPcoY8oLsywYqCXlQP9jE/Pcm5xicApA73LWH78MKXPjxI619mnHYUWJRi6/ob+2NpNNl0KKic/o/Dfj6kuzmLE4LznqvW/olwskYSGlSsGmanXOXV2lvDrrzBfjKH5PFKu0JOLs8QGe8yxR//0QDFgy4ZvT77T991ZrUaWXJyQS2KaCottx6/XrmHN8BC9tQFm6ilRFBFbQ784Vo4d0uVHR9+JQ7vlwI5tDwQAZ/a9P73hskveKC+rjuYrlc1po9FfiiOmZuYoVsqUeqqUqv3sPfgJ+0YPd95ulFAYGBjLVyv3RGl999sPPzp13o596713xjNz87uWFfKPT42P99LK6KtWGT12isgKW39zEYWB2owzwe7jJ06+9NHefefv2D+OXQ/e39dopo/NfjN1Xzo3G9WXGgyvW5cVyj17Gln25Jsjr/2yP+XHcdvOuzYmUfSUqieXJA+/8PTzP/vrfQ+RWRlqfwVLxwAAAABJRU5ErkJggg==',
      },
    },
  },
  {
    name: 'Topher Lamey',
    position: 'Sr. Full Stack Engineer',
    picture: {
      images: {
        sources: [
          {
            srcSet:
              '//images.ctfassets.net/nx13ojx82pll/5thOl6IgF1NB1U7GUaNV9r/c064f8af5e18db9db112ffc1c133b490/topher-lamey_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=webp 28w,\n//images.ctfassets.net/nx13ojx82pll/5thOl6IgF1NB1U7GUaNV9r/c064f8af5e18db9db112ffc1c133b490/topher-lamey_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=webp 55w,\n//images.ctfassets.net/nx13ojx82pll/5thOl6IgF1NB1U7GUaNV9r/c064f8af5e18db9db112ffc1c133b490/topher-lamey_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=webp 110w',
            sizes: '(min-width: 110px) 110px, 100vw',
            type: 'image/webp',
          },
        ],
        fallback: {
          src: '//images.ctfassets.net/nx13ojx82pll/5thOl6IgF1NB1U7GUaNV9r/c064f8af5e18db9db112ffc1c133b490/topher-lamey_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png',
          srcSet:
            '//images.ctfassets.net/nx13ojx82pll/5thOl6IgF1NB1U7GUaNV9r/c064f8af5e18db9db112ffc1c133b490/topher-lamey_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=28&h=33&q=90&fm=png 28w,\n//images.ctfassets.net/nx13ojx82pll/5thOl6IgF1NB1U7GUaNV9r/c064f8af5e18db9db112ffc1c133b490/topher-lamey_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=55&h=64&q=90&fm=png 55w,\n//images.ctfassets.net/nx13ojx82pll/5thOl6IgF1NB1U7GUaNV9r/c064f8af5e18db9db112ffc1c133b490/topher-lamey_2x-1-ow5g5gpull4tl2eh13ghu9umacjofjckzohzc1m3k0.png?w=110&h=128&q=90&fm=png 110w',
          sizes: '(min-width: 110px) 110px, 100vw',
        },
      },
      layout: 'constrained',
      width: 110,
      height: 128,
      placeholder: {
        fallback:
          'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAYAAAALHW+jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QUSFR0sb/RRkQAABJJJREFUOMuNlVtsVFUUhr+9zz7nTDudDnSm9DKtFBDQtiB4ARJ8ACFgAcFr4qM+KCa8Cj6JEp4E3/Sh0ChBSdBEgxilab0EEWNABSm1RAtaWmgLQynTTi9zLnv7MKWAIrKSlbP3zllf1vrXOXsJ/seqPzpQrzFvIQTSkq/1PLuh/U7vi/8E7f8kCWLraBhuzHi+I6RFvMD1Cm27UUqxvevJtVfuClj94ccuUryc0+aNIS9IeFojpYWUEqTAthWxiDtgW3IbsLtnw5rcbYFVe/YJIUVDgNg55AW1I6HGDgKKe7qQ2WFMWTm6vIJsYRSNxLUVxa7doaTYbBDNF9Y/biaB1U176rS03s4G4ephPxDaUqjcOGV7dzFldJTQ9yiORVGRCB1LVzL80CJCBFJAzLFNkaNaJLzas77hN1HV2PTumB++lAlCJxACLBuUTekXnxJv+wXHsrhvejWuJSiIRGjt7CK95U1yRdF8PsagpCDu2F6BsprUWM7bNDCWA9sBJUEI1NgIdQN9vPjC88yZOYO5c2sRUmL8MXa89wHvdHaQe3DRpG6BNgyMe07CtTdJc4uU+WdkeIgltXNYMGcms1IpIo5DYSRCxLVZ/ehiKsPwXzEARggU5qYWTaxjuXFGA8HJv/poP5+mvDyFDnyMKKDAraCmaJjOSZgBIcBowKAmScYABktrll3qRuqA7vOdFETj4Ic4tuFKNsPsVJKnXRj1R/lBFU6Emwm4QOU3t2Z4r4REMsGyhfPIZsdBK8pKS3BjLrnAp3osy36rEIKJGAOgwRgkxkCoQecPQuBkeTXoED0yTrr7AsdOnKDvQi/pzj9xw5DLyuWof6OqSRmNQaJD8q4n/etEBYMY+rODnB1IU1c7h9i0Ei77Oa6OZGjK5NDXKzPXS2ZCQ0MeFIYgJWjBuGXTJiKsKpnK8roohdEpxOMlpCpKONfXyxHcfMxNmeX7ABKj8x0y+UyF1qjTp+g6cpijbWcYtiwKolEsS3Epk6Wlq4/zygFhJnS71dVkqcaA0cjMNSKtLTxQW0lHZye/n7/IIzNnE4vH6ezvZjSbIfrHZbKrVk58LjdpqTVK6vxCpq+g2k6j2tsR2lBUVEx1aQlne9McP3eGmlSK+6eXY6kq9u06iM5cw1u8iLAqhZkACwNW+fLHSgt/PLZAHD5iWb19CKMJA58R37B0/lymxKJMSySYfU8VldOSdGdyHD5+GtFzEeenn1Ht7VjJJPGKcq9AWY3W4LffHKqqnnGgOJGcaTvuLN/zReDluDIwSNYLWLJgHlOLolRVVpIOHN7/8nt6+y5i2xGU41LsuiY5dK1FPrzwmZ7nnto7+SPWrVgjJLIh8L2dmf5LtdmrA3hjI9TUzGBdwxNYyubS1Ws0H/qckWyGRFUNsdJkh1TWZiFk86nWz27chzfbvBXrXCHERm90bOtgf38iHlHU1y8kkSyjpbUZH0ikUgMq4m7TWu9u++rg7W/sf1r9irVJo83rXnbklWA445RVpEgPD3nRqVMbpbS2/9py4O5myj9t/sr19YGf22GMBiG2nPmu9Y5T7283uxVkbUNuWAAAAABJRU5ErkJggg==',
      },
    },
  },
];
