import React from 'react';
import { AboutPreHeaderTestId, defaultContent } from './constants';
import PreHeaderBackgroundContainer from '../PreHeaderBackgroundContainer';

export interface Props {
  content?: React.ReactNode;
}

export const AboutPreHeader: React.FC<Props> = ({ content = defaultContent }) => {
  return (
    <section data-testid={AboutPreHeaderTestId} className="container-fluid px-0">
      <PreHeaderBackgroundContainer>
        <div className="container py-md-11 py-5">
          <div className="row">
            <div className="col-md-9 col-12 mx-auto bg-asphalt py-md-5 px-md-5 p-3">
              <h2 className="fz-24 text-white font-weight-bold lh-187 mb-0">{content}</h2>
            </div>
          </div>
        </div>
      </PreHeaderBackgroundContainer>
    </section>
  );
};

export default AboutPreHeader;
